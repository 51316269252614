<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card class="pa-4">
      <div class="d-flex align-center mb-6">
        <div class="text-h6">
          {{ nicheID ? "Editar nicho" : "Adicionar novo nicho" }}
        </div>

        <v-spacer />

        <v-btn color="primary" :disabled="loading" small icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-form v-model="valid" ref="form">
        <div class="mb-6" @keypress.enter.prevent="submit()">
          <v-text-field
            v-model="form.name"
            label="Nome do nicho"
            class="mb-4"
            :rules="_rules"
          />
        </div>

        <v-btn
          class="text-none rounded-lg"
          color="primary"
          :loading="loading"
          depressed
          large
          block
          @click="submit()"
        >
          {{ nicheID ? "Salvar alterações" : "Adicionar" }}
        </v-btn>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";

export default {
  name: "ManageNiche",

  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      nicheID: "",
      form: {
        name: "",
      },
    };
  },

  computed: {
    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "uploadNincho",
          nomeNincho: this.form.name,
          ...(this.nicheID && { idNincho: this.nicheID }),
        };

        await request(payload);

        this.handleAlert(
          this.nicheID
            ? "Nicho atualizado com sucesso"
            : "Nicho adicionado com sucesso",
          "success"
        );

        this.$emit("update");
        this.close();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open(data) {
      this.nicheID = data ? data.id : "";

      this.form = {
        name: data ? data.name : "",
      };

      this.dialog = true;
      if (this.$refs.form) this.$refs.form.resetValidation();
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
