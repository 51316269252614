<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card class="pa-4">
      <div class="d-flex align-center mb-6">
        <div class="text-h6">Cancelar assinatura</div>

        <v-spacer />

        <v-btn color="primary" :disabled="loading" small icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <section class="mb-8">
        <div class="text-body-1">
          Deseja cancelar esta assinatura deste usuário?
        </div>
      </section>

      <div class="d-flex justify-end">
        <v-btn
          v-if="!loading"
          class="text-none rounded-lg mr-2"
          :loading="loading"
          text
          @click="close()"
        >
          Cancelar
        </v-btn>

        <v-btn
          class="text-none rounded-lg"
          color="primary"
          :loading="loading"
          depressed
          @click="submit()"
        >
          Confirmar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";

export default {
  name: "CancelSignature",

  data() {
    return {
      loading: false,
      dialog: false,
      subscriptionID: "",
    };
  },

  computed: {
    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        const payload = {
          method: "deleteCreatorsSubscriptions",
          userID: this.$route.params.id,
          subcriptionID: this.subscriptionID,
        };

        await request(payload);

        this.handleAlert("Assinatura cancelada com sucesso", "success");

        this.$emit("update");
        this.close();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open(data) {
      this.subscriptionID = data.id;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
