<template>
  <div class="documents-wrapper">
    <v-card class="pa-4 rounded-lg mb-6" flat>
      <div class="d-flex mb-3">
        <div class="font-weight-bold">Envio de documentos</div>

        <v-spacer />

        <div v-if="brand.documents">
          <a
            class="font-weight-bold secondary--text"
            target="_blank"
            :href="brand.documents.site"
          >
            <v-icon color="secondary" left small> mdi-link </v-icon>Site da
            marca
          </a>
        </div>
      </div>

      <v-card class="foreground rounded-lg mb-6" flat>
        <v-row no-gutters>
          <v-col v-if="brand.documents" class="pa-4" cols="12" sm="6" md="3">
            <div>CNPJ - {{ brand.documents.cnpj }}</div>

            <a target="_blank" :href="brand.documents.cnpjDoc"> Visualizar </a>
          </v-col>

          <v-col v-if="brand.documents" class="pa-4" cols="12" sm="6" md="3">
            <div>Contrato social</div>

            <a target="_blank" :href="brand.documents.contract">Visualizar</a>
          </v-col>

          <v-col v-if="brand.documents" class="pa-4" cols="12" sm="6" md="3">
            <div>Documento do responsável</div>

            <a target="_blank" :href="brand.documents.responsibleDoc">
              Visualizar
            </a>
          </v-col>

          <v-col v-if="brand.documents" class="pa-4" cols="12" sm="6" md="3">
            <div>Selfie com documento</div>

            <a target="_blank" :href="brand.documents.selfie">Visualizar</a>
          </v-col>
        </v-row>
      </v-card>

      <div class="d-flex align-center">
        <div class="d-flex flex-column mr-6">
          <div class="font-weight-bold text-body-1 mb-n1">
            {{
              brand.documents
                ? handleStatus(brand.documents.status)
                : "Pendente"
            }}
          </div>

          <div class="text-caption">Status</div>
        </div>

        <v-spacer />

        <v-menu
          v-if="
            String(brand.documents.status) === '0' ||
            String(brand.documents.status) === '1'
          "
          :close-on-content-click="false"
          content-class="mt-2"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-model="rejectDocument.show"
              v-bind="attrs"
              v-on="on"
              class="text-none mr-2"
              color="primary"
              outlined
              small
            >
              Recusar
            </v-btn>
          </template>

          <v-card class="py-2 px-4" width="300">
            <v-text-field
              v-model="rejectDocument.motive"
              label="Motivo da rejeição"
            />

            <div class="d-flex">
              <v-spacer />

              <v-btn
                class="text-none rounded-lg"
                color="primary"
                depressed
                small
                @click="
                  rejectDocument.show = false;
                  handleChangeStatus('-1', rejectDocument.motive);
                "
              >
                Salvar
              </v-btn>
            </div>
          </v-card>
        </v-menu>

        <v-btn
          v-if="
            String(brand.documents.status) === '0' ||
            String(brand.documents.status) === '-1'
          "
          class="text-none mr-2"
          color="primary"
          depressed
          small
          @click="handleChangeStatus('1')"
        >
          Aprovar
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { request } from "@/services";

export default {
  name: "Documents",

  data() {
    return {
      loading: false,
      rejectDocument: {
        show: false,
        motive: "",
      },
    };
  },

  props: {
    brand: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    async handleChangeStatus(status, motive) {
      this.loading = true;

      const asyncFunctions = [
        this.changeDocumentStatus(status, motive),
        this.handleValidBrand(status),
      ];

      await Promise.all(asyncFunctions);

      this.$emit("refresh");

      this.loading = false;
    },

    async changeDocumentStatus(status, motive = "") {
      try {
        const payload = {
          method: "changeDocumentoStatusBrands",
          brandID: this.$route.params.id,
          status: status,
          motivoRecusa: motive,
        };

        await request(payload);
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async handleValidBrand(status) {
      try {
        const payload = {
          method: "setBrandValid",
          brandID: this.$route.params.id,
          valid: status === "1" ? status : "0",
        };

        await request(payload);
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    handleStatus(value) {
      switch (String(value)) {
        case "-1":
          return "Rejeitado";
        case "0":
          return "Em análise";
        case "1":
          return "Aprovado";
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
