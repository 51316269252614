import { render, staticRenderFns } from "./VideoTypes.vue?vue&type=template&id=08e9989e&scoped=true&"
import script from "./VideoTypes.vue?vue&type=script&lang=js&"
export * from "./VideoTypes.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&id=08e9989e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e9989e",
  null
  
)

export default component.exports