<template>
  <v-container fluid>
    <div class="d-flex flex-column flex-sm-row mb-6">
      <span
        class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-4 mb-md-0"
      >
        Dashboard
      </span>
    </div>

    <loader v-if="loading" />

    <section v-else>
      <v-row>
        <v-col v-for="(item, i) in _analytics" cols="12" md="4" :key="i">
          <v-card class="pa-4 rounded-lg" outlined>
            <div class="d-flex align-center">
              <v-avatar class="mr-4" color="primary">
                <v-icon dark>
                  {{ item.icon }}
                </v-icon>
              </v-avatar>

              <div class="d-flex flex-column">
                <div class="text-body-1 font-weight-bold grey--text">
                  {{ item.label }}
                </div>

                <div class="text-h5 font-weight-bold secondary--text">
                  {{ item.value }}
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card class="pt-4 pr-4 pb-2 rounded-lg" outlined>
            <div class="text-h6 ml-4 mb-n3">Detalhamento dos Criadores</div>

            <BarChart
              :series="_creatorsData.series"
              :categories="_creatorsData.categories"
            />
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card class="pt-4 pr-4 pb-2 rounded-lg" outlined>
            <div class="text-h6 ml-4 mb-n3">Detalhamento das Marcas</div>

            <BarChart
              :series="_brandsData.series"
              :categories="_brandsData.categories"
            />
          </v-card>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import { request } from "@/services";
import { currencyMask } from "@/utils";
import BarChart from "./partials/barChart/BarChart.vue";

export default {
  name: "Dashboard",

  components: {
    BarChart,
  },

  data() {
    return {
      loading: true,
      dashboard: {},
    };
  },

  beforeMount() {
    this.getDashboard();
  },

  computed: {
    _analytics() {
      return [
        {
          icon: "mdi-account",
          label: "Quantidade de Criadores",
          value: this.dashboard.totalCreators || 0,
        },
        {
          icon: "mdi-account-multiple",
          label: "Quantidade de Marcas",
          value: this.dashboard.totalBrands || 0,
        },
        {
          icon: "mdi-swap-horizontal",
          label: "Transacionado (Creators)",
          value: `R$ ` + currencyMask(this.dashboard.creatorTransacted || 0),
        },
        {
          icon: "mdi-shopping",
          label: "Quantidade de Vendas",
          value: this.dashboard.salesCounter || 0,
        },
        {
          icon: "mdi-currency-usd",
          label: "Total de Vendas",
          value: `R$ ` + currencyMask(this.dashboard.salesTotalValue || 0),
        },
      ];
    },

    _creatorsData() {
      const total = Number(this.dashboard.totalCreators) || 0;
      const approved = Number(this.dashboard.totalCreatorsApproved) || 0;
      const waiting = total - approved;
      const totalCreatorsHired = Number(this.dashboard.totalCreatorsHired) || 0;

      const totalMaluStudant = Number(this.dashboard.totalMaluStudant) || 0;
      const ttotalMaluStudantApprovedotal =
        Number(this.dashboard.totalMaluStudantApproved) || 0;
      const totalMaluStudantSubscriber =
        Number(this.dashboard.totalMaluStudantSubscriber) || 0;
      const totalMaluStudantFree =
        Number(this.dashboard.totalMaluStudantFree) || 0;

      return {
        series: [
          approved,
          waiting,
          totalCreatorsHired,
          totalMaluStudant,
          ttotalMaluStudantApprovedotal,
          totalMaluStudantSubscriber,
          totalMaluStudantFree,
        ],
        categories: [
          "Aprovados",
          "Aguardando",
          "Contratados",
          "AlunasMalu",
          "AlunasMalu Aprovados",
          "AlunasMalu Assinantes",
          "AlunasMalu Teste",
        ],
      };
    },

    _brandsData() {
      const total = Number(this.dashboard.totalBrands) || 0;
      const approved = Number(this.dashboard.totalBrandsApproved) || 0;
      const waiting = total - approved;

      return {
        series: [approved, waiting],
        categories: ["Aprovados", "Aguardando"],
      };
    },
  },

  methods: {
    async getDashboard() {
      try {
        const payload = {
          method: "getFinanceiroPage",
        };

        const { data } = await request(payload);

        this.dashboard = {
          totalCreators: data.totalCreators,
          totalCreatorsApproved: data.totalCreatorsAprovadas,
          totalCreatorsHired: data.totalCreatorsContratados,
          totalBrands: data.totalBrands,
          totalBrandsApproved: data.totalBrandsAprovadas,
          totalMaluStudant: data.totalAlunaMalu,
          totalMaluStudantApproved: data.totalAlunaMaluAprovadas,
          totalMaluStudantSubscriber: data.totalAlunosPagantes,
          totalMaluStudantFree: data.totalAlunosPeriodoTeste,
          creatorTransacted: data.totalTransacionadoCreators,
          salesCounter: data.totalVendas,
          salesTotalValue: data.totalVendasValor,
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
