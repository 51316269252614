import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Auth from "../views/auth/Auth.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import Creators from "../views/creators/Creators.vue";
import Withdrawals from "../views/withdrawals/Withdrawals.vue";
import Unsubscriptions from "../views/unsubscriptions/Unsubscriptions.vue";
import CancellationApplications from "../views/cancellationApplications/CancellationApplications.vue";
import Brands from "../views/brands/Brands.vue";
import CreatorDetails from "../views/creatorDetails/CreatorDetails.vue";
import BrandDetails from "../views/brandDetails/BrandDetails.vue";
import SocialMedia from "../views/socialMedia/SocialMedia.vue";
import Niches from "../views/niches/Niches.vue";
import VideoTypes from "../views/videoTypes/VideoTypes.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/auth",
    name: "Auth",
    meta: { preventAuth: true },
    component: Auth,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { requiredAuth: true },
    component: Dashboard,
  },
  {
    path: "/creators",
    to: "/creators/all",
    component: Creators,
    children: [
      {
        path: "all",
        name: "AllCreators",
        meta: { requiredAuth: true },
        component: () =>
          import("../views/creators/children/allCreators/AllCreators.vue"),
      },
      {
        path: "pending-approval",
        name: "CreatorsApproval",
        meta: { requiredAuth: true },
        component: () =>
          import(
            "../views/creators/children/creatorsApproval/CreatorsApproval.vue"
          ),
      },
    ],
  },
  {
    path: "/withdrawals",
    name: "Withdrawals",
    redirect: "/withdrawals/creators",
    component: Withdrawals,
    children: [
      {
        path: "creators",
        name: "CreatorsWithdrawals",
        meta: { requiredAuth: true },
        component: () =>
          import(
            "../views/withdrawals/children/creatorsWithdrawals/CreatorsWithdrawals"
          ),
      },
      {
        path: "brands",
        name: "BrandsWithdrawals",
        meta: { requiredAuth: true },
        component: () =>
          import(
            "../views/withdrawals/children/brandsWithdrawals/BrandsWithdrawals"
          ),
      },
    ],
  },
  {
    path: "/unsubscriptions",
    name: "Unsubscriptions",
    meta: { requiredAuth: true },
    component: Unsubscriptions,
  },
  {
    path: "/cancellation-applications",
    name: "CancellationApplications",
    meta: { requiredAuth: true },
    component: CancellationApplications,
  },
  {
    path: "/brands",
    to: "/brands/all",
    component: Brands,
    children: [
      {
        path: "all",
        name: "AllBrands",
        meta: { requiredAuth: true },
        component: () =>
          import("../views/brands/children/allBrands/AllBrands.vue"),
      },
      {
        path: "pending-approval",
        name: "BrandsApproval",
        meta: { requiredAuth: true },
        component: () =>
          import("../views/brands/children/brandsApproval/BrandsApproval.vue"),
      },
    ],
  },
  {
    path: "/creator-details/:id",
    name: "CreatorDetails",
    meta: { requiredAuth: true },
    component: CreatorDetails,
  },
  {
    path: "/brand-details/:id",
    name: "BrandDetails",
    meta: { requiredAuth: true },
    component: BrandDetails,
  },
  {
    path: "/social-media",
    name: "SocialMedia",
    meta: { requiredAuth: true },
    component: SocialMedia,
  },
  {
    path: "/niches",
    name: "Niches",
    meta: { requiredAuth: true },
    component: Niches,
  },
  {
    path: "/video-types",
    name: "VideoTypes",
    meta: { requiredAuth: true },
    component: VideoTypes,
  },
  {
    path: "*",
    redirect: "/dashboard",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
