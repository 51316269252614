<template>
  <v-container fluid>
    <div class="d-flex flex-column flex-sm-row mb-6">
      <span
        class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-4 mb-md-0"
      >
        Solicitações de cancelamento de assinatura
      </span>
    </div>

    <TabNavigation
      :tabs="tabs"
      type="query"
      keyValue="type"
      @change="handleTabChange($event)"
    />

    <v-divider class="surface mb-4" />

    <v-card class="pa-4 rounded-lg" flat>
      <FilterInput :filters="_filters" @filter="handleFilter($event)" />

      <loader v-if="loading.content" />

      <!-- content -->
      <v-data-table
        v-else
        :headers="headers"
        :items="unsubscriptions"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.user`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar class="mr-2" size="32" color="background">
              <v-img v-if="item.user.image" :src="item.user.image" />

              <v-icon v-else color="surface" small>
                mdi-account-outline
              </v-icon>
            </v-avatar>

            <div>
              {{ item.user.name }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.email`]="{ item }">
          {{ item.user.email }}
        </template>

        <template v-slot:[`item.status`]="{ item }">
          {{ handleStatus(item.status) }}
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            color="primary"
            :disabled="item.status !== '0'"
            small
            icon
            @click="handleUnsubscription(item, true)"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <Pagination
        :total="pagination.total"
        :limit="pagination.limit"
        @change="handlePagination($event)"
      />
    </v-card>

    <ManageUnsubscription ref="manageUnsubscription" @update="getData()" />
  </v-container>
</template>

<script>
import { request } from "@/services";
import { currencyMask, formatDate, fetchTotalPages } from "@/utils";
import TabNavigation from "@/components/tabNavigation/TabNavigation.vue";
import FilterInput from "@/components/filterInput/FilterInput.vue";
import ManageUnsubscription from "./partials/manageUnsubscription/ManageUnsubscription";
import Pagination from "@/components/pagination/Pagination.vue";

export default {
  components: {
    TabNavigation,
    FilterInput,
    Pagination,
    ManageUnsubscription,
  },

  data() {
    return {
      loading: {
        content: true,
        hover: false,
      },
      tabs: [
        {
          label: "Solicitações de Criadores",
          value: "creators",
        },
        {
          label: "Solicitações de Marcas",
          value: "brands",
        },
      ],
      filters: {},
      pagination: {
        page: 1,
        limit: 15,
        total: 0,
      },
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Usuário",
          value: "user",
        },
        {
          text: "E-mail",
          value: "email",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Data de solicitação",
          value: "createdAt",
        },
        { text: "Ações", value: "actions", width: "6rem" },
      ],
      unsubscriptions: [],
    };
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    _filters() {
      return [
        {
          label: "ID",
          key: "userID",
          type: "text",
        },
        {
          label: "Nome",
          key: "name",
          type: "text",
        },
        {
          label: "E-mail",
          key: "email",
          type: "text",
        },
        {
          label: "Usuário",
          key: "username",
          type: "text",
        },
      ];
    },
  },

  methods: {
    getData() {
      const type = this.$route.query.type;

      if (type && type === "brands") this.getBrandsUnsubscriptions();
      else this.getCreatorUnsubscriptions();
    },

    async getCreatorUnsubscriptions() {
      try {
        const payload = {
          method: "listCancelamentosCreators",
          page: this.pagination.page,
          ...(this.filters.userID && { FiltroUserID: this.filters.userID }),
          ...(this.filters.name && { nameFilter: this.filters.name }),
          ...(this.filters.email && { emailFilter: this.filters.email }),
          ...(this.filters.username && {
            FiltroUsername: this.filters.username,
          }),
        };

        const { data } = await request(payload);

        this.unsubscriptions = data.cancelamentos.map((el) => ({
          id: el.id,
          user: {
            id: el.userID,
            name: el.nome,
            email: el.email,
            username: el.username,
            image: process.env.VUE_APP_API_URL + "/" + el.userImage,
          },
          status: el.status,
          createdAt: el.dataPedido,
        }));

        this.pagination.total = fetchTotalPages(data.total);
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading.content = false;
      }
    },

    async getBrandsUnsubscriptions() {
      try {
        const payload = {
          method: "listCancelamentosBrands",
          page: this.pagination.page,
          ...(this.filters.userID && { FiltroUserID: this.filters.userID }),
          ...(this.filters.name && { nameFilter: this.filters.name }),
          ...(this.filters.email && { emailFilter: this.filters.email }),
          ...(this.filters.username && {
            FiltroUsername: this.filters.username,
          }),
        };

        const { data } = await request(payload);

        this.unsubscriptions = data.cancelamentos.map((el) => ({
          id: el.id,
          user: {
            id: el.brandID,
            name: el.nome,
            email: el.email,
            username: el.username,
            image: process.env.VUE_APP_API_URL + "/" + el.userImage,
          },
          status: el.status,
          createdAt: el.dataPedido,
        }));

        this.pagination.total = fetchTotalPages(data.total);
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading.content = false;
      }
    },

    handleStatus(value) {
      const options = {
        "-1": "Recusado",
        0: "Pendente",
        1: "Aprovado",
      };

      return options[value];
    },

    handleTabChange() {
      this.pagination.page = 1;

      this.getData();
    },

    handleFilter(event) {
      this.filters = {
        ...this.filters,
        ...event,
      };

      this.pagination.page = 1;

      this.getData();
    },

    handlePagination(event) {
      this.pagination.page = event.page;

      this.getData();
    },

    handleUnsubscription(data, value) {
      this.$refs.manageUnsubscription.open(data, value);
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    currencyMask,

    formatDate,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
