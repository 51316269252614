<template>
  <v-container fluid>
    <div
      class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-6"
    >
      Detalhes da marca
    </div>

    <loader v-if="loading.content" class="mt-6" />

    <section v-else>
      <div class="d-flex justify-end mb-4">
        <v-btn
          class="text-none rounded-lg mr-2"
          color="primary"
          depressed
          @click="handleValidBrand()"
        >
          {{ brand.valid ? "Desaprovar conta" : "Aprovar conta" }}
        </v-btn>

        <v-btn
          class="text-none rounded-lg"
          color="primary"
          depressed
          @click="viewBrand()"
        >
          Acessar conta
        </v-btn>
      </div>

      <v-row class="mb-1">
        <v-col cols="12" md="9" xl="10">
          <v-card class="pa-4 rounded-lg" height="100%" flat>
            <div class="d-flex flex-column flex-md-row">
              <div class="d-flex flex-column align-center mr-6 mb-4 mb-md-0">
                <v-avatar size="6rem" color="surface">
                  <v-img v-if="brand.image" :src="brand.image" />

                  <v-icon v-else size="2.25rem" color="surface lighten-1">
                    mdi-account-outline
                  </v-icon>
                </v-avatar>

                <v-chip color="secondary" class="mt-n4" label small>
                  {{ brand.subscriber ? "Free" : "Assinante" }}
                </v-chip>
              </div>

              <div class="d-flex flex-column">
                <div class="text-body-1 font-weight-bold">
                  {{ brand.name }}

                  <span class="text-body-2"> @{{ brand.username }} </span>
                </div>

                <div v-if="brand.biography" class="text-caption text2--text">
                  {{ brand.biography }}
                </div>

                <div class="text-body-2 mt-2">
                  {{ brand.email }}
                </div>

                <div v-if="brand.phone" class="text-body-2">
                  {{ brand.phone }}
                </div>

                <div v-if="brand.document" class="text-body-2">
                  {{ brand.document }}
                </div>
              </div>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" xl="2">
          <v-card class="pa-4 rounded-lg" height="100%" flat>
            <v-switch
              v-model="form.demoAccount"
              class="ma-0 pa-0 mb-2"
              label="Conta demo"
              color="primary"
              :loading="loading.demo"
              :disabled="loading.demo"
              hide-details
              dense
              inset
              @click="handleChangeDemoAccount()"
            ></v-switch>

            <div class="d-flex align-center">
              <div class="d-flex flex-column">
                <div class="primary--text text-h6 mb-n1">
                  R$ {{ currencyMask(brand.balance) }}
                </div>

                <div class="mb-2 text-body-2">Saldo da conta</div>
              </div>

              <v-spacer />

              <v-btn
                v-if="form.demoAccount && !loading.demo"
                icon
                small
                @click="handleAddBalance()"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </div>

            <div class="d-flex align-center">
              <div class="d-flex flex-column mr-12">
                <div class="primary--text text-body-2 font-weight-bold mb-n1">
                  {{ brand.rates.card }}%
                </div>

                <div class="mb-2 text-caption">Taxa cartão</div>
              </div>

              <div class="d-flex flex-column">
                <div class="primary--text text-body-2 font-weight-bold mb-n1">
                  {{ brand.rates.pix }}%
                </div>

                <div class="mb-2 text-caption">Taxa Pix</div>
              </div>

              <v-spacer />

              <v-btn icon small @click="handleEditRates()">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <Documents :brand="brand" @refresh="getBrand()" />

      <BankData :brand="brand" />

      <v-card class="pa-4 rounded-lg mb-6" flat>
        <div class="d-flex mb-3">
          <div class="font-weight-bold">Assinaturas</div>

          <v-spacer />

          <v-btn
            class="text-none rounded-lg"
            color="primary"
            depressed
            small
            @click="handleAddSignature()"
          >
            Adicionar assinatura
          </v-btn>
        </div>

        <v-data-table
          :headers="headers"
          :items="signatures"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ formatDate(item.updatedAt) }}
          </template>

          <template v-slot:[`item.nextCharge`]="{ item }">
            {{ formatDate(item.nextCharge) }}
          </template>

          <template v-slot:[`item.active`]="{ item }">
            {{ item.active ? "Sim" : "Não" }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              color="primary"
              small
              icon
              @click="handleCancelSignature(item)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </section>

    <AddSignature ref="addSignature" @update="getBrand()" />

    <CancelSignature ref="cancelSignature" @update="getBrand()" />

    <EditRates ref="editRates" @update="getBrand()" />

    <AddBalance ref="addBalance" @update="getBrand()" />
  </v-container>
</template>

<script>
import { request } from "@/services";
import { currencyMask, formatDate } from "@/utils";
import AddSignature from "./partials/addSignature/AddSignature";
import CancelSignature from "./partials/cancelSignature/CancelSignature";
import Documents from "./partials/documents/Documents";
import BankData from "./partials/bankData/BankData";
import EditRates from "./partials/editRates/EditRates";
import AddBalance from "./partials/addBalance/AddBalance";

export default {
  components: {
    AddSignature,
    CancelSignature,
    Documents,
    BankData,
    EditRates,
    AddBalance,
  },

  data() {
    return {
      loading: {
        content: true,
        demo: false,
      },
      brand: null,
      form: {
        demoAccount: false,
      },
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Data de solicitação",
          value: "createdAt",
        },
        {
          text: "Data de atualização",
          value: "updatedAt",
        },
        {
          text: "Próxima cobrança",
          value: "nextCharge",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Ativa",
          value: "active",
        },
        {
          text: "Ações",
          value: "actions",
        },
      ],
      signatures: [],
    };
  },

  beforeMount() {
    this.getBrand();
  },

  methods: {
    async getBrand() {
      try {
        this.loading.content = true;

        const payload = {
          method: "getBrandData",
          brandID: this.$route.params.id,
        };

        const { data } = await request(payload);

        const apiURL = process.env.VUE_APP_API_URL + "/";

        this.brand = {
          id: data.brandData.id,
          username: data.brandData.username,
          biography: data.brandData.biografia,
          name: data.brandData.nome,
          email: data.brandData.email,
          image: apiURL + data.brandData.userImage,
          document: data.brandData.cpfCnpj,
          balance: Number(data.brandData.saldo),
          phone: data.brandData.telefone,
          createdAt: data.brandData.registerDate,
          subscriber: data.brandData.plano === "1",
          valid: data.brandData.valid === "1",
          termsAccepted: data.brandData.aceitouTermos === "1",
          ...(data.brandData.documentos &&
            Object.keys(data.brandData.documentos).length && {
              documents: {
                cnpjDoc: apiURL + data.brandData.documentos.cartaoCNPJ,
                contract: apiURL + data.brandData.documentos.contratoSocial,
                responsibleDoc:
                  apiURL + data.brandData.documentos.docResponsavel,
                selfie: apiURL + data.brandData.documentos.docSelfie,
                cnpj: data.brandData.documentos.numCNPJ,
                site: data.brandData.documentos.siteMarca,
                status: data.brandData.documentos.status,
              },
            }),
          ...(data.brandData.bankAccount && {
            bankAccount: {
              id: data.brandData.bankAccount.id,
              bank: data.brandData.bankAccount.banco,
              agency: data.brandData.bankAccount.agencia,
              account: data.brandData.bankAccount.conta,
              digit: data.brandData.bankAccount.digito,
              date: data.brandData.bankAccount.dataEnvio,
              pixKey: {
                type: data.brandData.bankAccount.tipoChave || "",
                key: data.brandData.bankAccount.chavePix || "",
              },
            },
          }),
          rates: {
            pix: Number(data.brandData.taxaPix),
            card: Number(data.brandData.taxaCartao),
          },
          demoAccount: data.brandData.contaDemo === "1",
        };

        this.form.demoAccount = this.brand.demoAccount;

        this.signatures = (data.brandData.assinatura?.assinaturas || []).map(
          (el) => ({
            id: el.id,
            subscriptionID: el.assinaturaID,
            createdAt: el.dataCriacao,
            updatedAt: el.ultimaAlteraceo,
            nextCharge: el.proxCobranca,
            status: el.statusAssinatura,
            valid: el.valid === "1",
          })
        );
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading.content = false;
      }
    },

    async handleChangeDemoAccount() {
      try {
        this.loading.demo = true;

        const payload = {
          method: "setContaDemoBrands",
          status: this.form.demoAccount ? "1" : "0",
          brandID: this.$route.params.id,
        };

        await request(payload);

        this.handleAlert("Conta demo alterada com sucesso", "success");
      } catch (error) {
        this.form.demoAccount = !this.form.demoAccount;
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading.demo = false;
      }
    },

    async handleValidBrand() {
      try {
        const payload = {
          method: "setBrandValid",
          brandID: this.$route.params.id,
          valid: this.brand.valid ? "0" : "1",
        };

        await request(payload);
        this.getBrand();
      } catch (error) {
        console.log(error);
      }
    },

    async viewBrand() {
      try {
        const payload = {
          method: "getBrandToken",
          brandID: this.$route.params.id,
        };

        const { data } = await request(payload);

        const url = `${process.env.VUE_APP_CLIENT_BRANDS_URL}/auth?tk=${data.token}`;
        window.open(url, "_target");
      } catch (error) {
        console.log(error);
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    handleAddBalance() {
      this.$refs.addBalance.open();
    },

    handleAddSignature() {
      this.$refs.addSignature.open();
    },

    handleCancelSignature(data) {
      this.$refs.cancelSignature.open(data);
    },

    handleEditRates() {
      this.$refs.editRates.open(this.brand.rates);
    },

    currencyMask,

    formatDate,
  },
};
</script>

<style></style>
