<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card class="pa-4">
      <div class="d-flex align-center mb-6">
        <div class="text-h6">Adicionar assinatura</div>

        <v-spacer />

        <v-btn color="primary" :disabled="loading" small icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <section class="mb-8">
        <v-form v-model="valid" ref="form" autocomplete="off">
          <div @keypress.enter.prevent="submit()">
            <v-menu
              v-model="menuValidate"
              transition="scale-transition"
              max-width="18.125rem"
              min-width="auto"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Validade"
                  append-icon="mdi-calendar"
                  class="mb-4"
                  readonly
                  :value="_formattedValidate"
                  :rules="_rules"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>

              <v-date-picker
                v-model="form.validate"
                locale="pt-BR"
                class="mb-4"
                no-title
                :min="_minDate"
                @input="menuValidate = false"
              />
            </v-menu>

            <v-select
              v-model="form.planID"
              class="mb-4"
              label="Plano"
              item-text="text"
              item-value="value"
              :items="plans"
              :rules="_rules"
            />
          </div>
        </v-form>
      </section>

      <div class="d-flex justify-end">
        <v-btn
          v-if="!loading"
          class="text-none rounded-lg mr-2"
          :loading="loading"
          text
          @click="close()"
        >
          Cancelar
        </v-btn>

        <v-btn
          class="text-none rounded-lg"
          color="primary"
          :loading="loading"
          depressed
          @click="submit()"
        >
          Confirmar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
const moment = require("moment");

export default {
  name: "CancelSignature",

  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      menuValidate: false,
      plans: [
        {
          value: "1",
          text: "Pacote Light",
        },
        {
          value: "2",
          text: "Pacote Standard",
        },
        {
          value: "3",
          text: "Pacote Gold",
        },
        {
          value: "4",
          text: "Pacote Escala",
        },
      ],
      form: {
        validate: "",
        planID: "",
      },
    };
  },

  computed: {
    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },

    _minDate() {
      const today = new Date();
      today.setDate(today.getDate());

      return today.toISOString().substr(0, 10);
    },

    _formattedValidate() {
      if (!this.form.validate) return "";

      return moment(this.form.validate).format("DD/MM/YYYY");
    },
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        const payload = {
          method: "giveBrandsSubscriptions",
          brandID: this.$route.params.id,
          dataValidade: this.form.validate,
          planID: this.form.planID,
        };

        await request(payload);

        this.handleAlert("Assinatura aplicada com sucesso", "success");

        this.$emit("update");
        this.close();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open() {
      this.dialog = true;

      if (this.$refs.form) this.$refs.form.reset();
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
