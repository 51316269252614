<template>
  <v-tabs
    v-model="tab"
    background-color="transparent"
    height="2.5rem"
    show-arrows
  >
    <v-tab
      v-for="(item, i) in tabs"
      class="text-none black--text label"
      :disabled="disabled"
      :key="i"
      @click="submit(item.value, i)"
    >
      {{ item.label }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  data() {
    return {
      tab: 0,
    };
  },

  props: {
    tabs: {
      type: Array,
      default: () => [], // [{ label, value }]
    },

    type: {
      type: String, // accept: value, path or query
      default: "value",
    },

    keyValue: {
      type: String, // value to event (root path or query name)
      default: "",
    },

    default: {
      type: String, // used by start value
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  beforeMount() {
    this.handleCurrent();
  },

  methods: {
    submit(value, index) {
      this.tab = index;

      if (this.type === "query") {
        const query = {
          [this.keyValue || "value"]: value,
        };

        if (this.$route.query[this.keyValue || "value"] === value) return;

        this.$router.replace({ query: query }).catch({
          /* ignore */
        });
      }

      if (this.type === "path") {
        const path = this.keyValue ? this.keyValue + value : value;
        if (this.$route.path !== path) this.$router.push({ path: path });
      }

      this.$emit("change", { [this.keyValue || "value"]: value });
    },

    handleCurrent() {
      if (this.default) {
        this.tab = this.tabs.findIndex((el) => el.value === this.default);
      } else if (this.type === "query") {
        const query =
          this.default || this.$route.query[this.keyValue || "value"];
        this.tab = this.tabs.findIndex((el) => el.value === query);
      } else if (this.type === "path") {
        const path = this.default || this.$route.path;
        this.tab = this.tabs.findIndex((el) => path.includes(el.value));
      }

      if (this.tab < 0) this.tab = 0;
      this.submit(this.tabs[this.tab].value, this.tab);
    },

    setTab(value) {
      this.tab = this.tabs.findIndex((el) => el.value === value);
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
