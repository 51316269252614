<template>
  <v-container fluid>
    <div class="d-flex flex-column flex-sm-row mb-6">
      <span
        class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-4 mb-md-0"
      >
        Gerenciar tipos de vídeo
      </span>

      <v-spacer />

      <v-btn
        class="text-none"
        color="primary"
        depressed
        small
        @click="handleVideoType()"
      >
        <v-icon left>mdi-plus</v-icon>
        Adicionar novo
      </v-btn>
    </div>

    <loader v-if="loading.content" />

    <v-card v-else class="pa-4 rounded-lg" flat>
      <!-- content -->

      <v-data-table
        :headers="headers"
        :items="videoTypes"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.image`]="{ item }">
          <v-avatar class="mr-2" size="32" color="background">
            <v-img v-if="item.image" :src="item.image" />

            <v-icon v-else color="surface" small> mdi-account-outline </v-icon>
          </v-avatar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="mr-2"
            color="primary"
            small
            icon
            @click="handleVideoType(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn color="primary" small icon @click="handleDelete(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <ManageVideoType ref="manageVideoType" @update="getVideoTypes()" />

    <loader-hover v-if="loading.hover" />
  </v-container>
</template>

<script>
import { request } from "@/services";
import ManageVideoType from "./partials/manageVideoType/ManageVideoType";

export default {
  components: {
    ManageVideoType,
  },

  data() {
    return {
      loading: {
        content: true,
        hover: false,
      },
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        { text: "Ações", value: "actions", width: "6rem" },
      ],
      videoTypes: [],
    };
  },

  beforeMount() {
    this.getVideoTypes();
  },

  methods: {
    async getVideoTypes() {
      try {
        const payload = {
          method: "listTipoVideo",
        };

        const { data } = await request(payload);

        this.videoTypes = data.tipoVideo.map((el) => ({
          id: el.id,
          name: el.tipoVideo,
        }));
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading.content = false;
      }
    },

    async handleDelete(data) {
      try {
        this.loading.hover = true;

        const payload = {
          method: "deleteTipoVideo",
          idTIpoVideo: data.id,
        };

        await request(payload);

        this.handleAlert("Tipo removido com sucesso", "success");
        this.getVideoTypes();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading.hover = false;
      }
    },

    handleVideoType(data) {
      this.$refs.manageVideoType.open(data);
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
