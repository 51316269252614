<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card class="pa-4">
      <div class="d-flex align-center mb-6">
        <div class="text-h6">Alteraração de saldo</div>

        <v-spacer />

        <v-btn color="primary" :disabled="loading" small icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <section class="mb-8">
        <v-form v-model="valid" ref="form" autocomplete="off">
          <div @keypress.enter.prevent="submit()">
            <v-text-field
              v-model="form.value"
              v-money="_money"
              prefix="R$"
              ref="balance"
              id="balance"
              label="Novo valor de saldo"
              :rules="_rules"
            />
          </div>
        </v-form>
      </section>

      <div class="d-flex justify-end">
        <v-btn
          v-if="!loading"
          class="text-none rounded-lg mr-2"
          :loading="loading"
          text
          @click="close()"
        >
          Cancelar
        </v-btn>

        <v-btn
          class="text-none rounded-lg"
          color="primary"
          :loading="loading"
          depressed
          @click="submit()"
        >
          Confirmar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { parseNumber } from "@/utils";
import { VMoney } from "v-money";

export default {
  name: "AddBalance",

  directives: {
    money: VMoney,
  },

  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      form: {
        value: "",
      },
    };
  },

  computed: {
    _money() {
      return {
        decimal: ",",
        thousands: ".",
        precision: 2,
      };
    },

    _rules() {
      return [(v) => (!!v && parseNumber(v) >= 0) || "Valor inválido"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "setSaldoDemoBrands",
          brandID: this.$route.params.id,
          valor: parseNumber(this.form.value),
        };

        await request(payload);

        this.handleAlert("Saldo alterado com sucesso", "success");

        this.$emit("update");
        this.close();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open() {
      this.dialog = true;

      this.form.value = "0,00";

      this.$nextTick(() => {
        if (this.$refs.balance) {
          this.$refs.balance.$refs.input.value = "0,00";
          this.$refs.balance.$emit("input", "0,00");
        }
      });
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
