<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card class="pa-4">
      <div class="d-flex align-center mb-6">
        <div class="text-h6">Editar taxas</div>

        <v-spacer />

        <v-btn color="primary" :disabled="loading" small icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <section class="mb-8">
        <v-form v-model="valid" ref="form" autocomplete="off">
          <div @keypress.enter.prevent="submit()">
            <v-text-field
              v-model="form.pix"
              label="Taxa PIX"
              class="mb-4"
              suffix="%"
              :rules="_rules"
              @keypress="onlyFloat"
            />

            <v-text-field
              v-model="form.card"
              label="Taxa Cartão"
              class="mb-4"
              suffix="%"
              :rules="_rules"
              @keypress="onlyFloat"
            />
          </div>
        </v-form>
      </section>

      <div class="d-flex justify-end">
        <v-btn
          v-if="!loading"
          class="text-none rounded-lg mr-2"
          :loading="loading"
          text
          @click="close()"
        >
          Cancelar
        </v-btn>

        <v-btn
          class="text-none rounded-lg"
          color="primary"
          :loading="loading"
          depressed
          @click="submit()"
        >
          Confirmar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { onlyFloat } from "@/utils";

export default {
  name: "EditRates",

  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      form: {
        pix: 0,
        card: 0,
      },
    };
  },

  computed: {
    _rules() {
      return [(v) => v >= 0 || "Valor inválido"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "setBrandTaxas",
          brandID: this.$route.params.id,
          taxaPix: this.form.pix,
          taxaCartao: this.form.card,
        };

        await request(payload);

        this.handleAlert("Taxas alteradas com sucesso", "success");

        this.$emit("update");
        this.close();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open(data) {
      this.form = structuredClone(data);
      this.dialog = true;

      if (this.$refs.form) this.$refs.form.resetValidation();
    },

    close() {
      this.dialog = false;
    },

    onlyFloat,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
