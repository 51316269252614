<template>
  <v-container class="withdrawals-wapper" fluid>
    <div class="d-flex flex-column flex-sm-row mb-6">
      <span
        class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-4 mb-md-0"
      >
        Solicitações de saque
      </span>
    </div>

    <TabNavigation :tabs="tabs" type="path" keyValue="/withdrawals" />

    <v-divider class="surface mb-4" />

    <router-view />
  </v-container>
</template>

<script>
import TabNavigation from "@/components/tabNavigation/TabNavigation.vue";

export default {
  components: {
    TabNavigation,
  },

  data() {
    return {
      tabs: [
        {
          label: "Criadores",
          value: "/creators",
        },
        {
          label: "Marcas",
          value: "/brands",
        },
      ],
    };
  },
};
</script>

<style></style>
