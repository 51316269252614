import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#FF664F",
        secondary: "#533EF9",
        accent: "#FED8B1",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        neutral: "#FFFFFF",
        background: "#e7e9ef",
        foreground: "#f8f8fa",
        surface: "#d2d7e5",
        text1: "#2B2B2B",
        text2: "#818080",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
