import axios from "axios";
import store from "@/store";
import router from "@/router";

export const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/adminapp",
  timeout: 30000,
  headers: { secret: process.env.VUE_APP_API_SECRET },
});

http.defaults.headers.common["AdminAuthorization"] = store.getters.token || "";

const api = async () => {
  // interceptor
  http.interceptors.response.use(
    (res) => {
      if (res.data && res.data.status === "OK") return Promise.resolve(res);

      return Promise.reject(res);
    },
    (err) => {
      if (
        (err.message && err.message.includes("Network Error")) ||
        (err.request.status >= 500 && store.getters.token) ||
        [401, 403].includes(err.request.status)
      ) {
        store.dispatch("setSignOut");
        router.push({ path: "/" }).catch(() => {
          /* ignore */
        });
      }

      return Promise.reject(err);
    }
  );

  return http;
};

export async function request(data) {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return (await api()).post("", formData);
}

export default api;
