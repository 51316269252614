<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card class="pa-4">
      <div class="d-flex align-center mb-6">
        <div class="text-h6">Adicionar tag de verificação</div>

        <v-spacer />

        <v-btn color="primary" :disabled="loading" small icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <section class="mb-8">
        <v-form v-model="valid" ref="form" autocomplete="off">
          <div class="mb-4">
            <div class="text-body-2 font-weight-bold">Tag de verificação</div>

            <v-switch
              v-model="form.flag"
              class="mt-1"
              label="Malu"
              color="primary"
              value="malu"
              hide-details
              dense
              inset
            ></v-switch>

            <v-switch
              v-model="form.flag"
              class="mt-1"
              label="Carol"
              color="primary"
              value="carol"
              dense
              inset
            ></v-switch>

            <div v-if="!form.flag" class="red--text text-caption ml-2">
              Selecione uma flag
            </div>
          </div>

          <div class="text-body-1 font-weight-bold mb-3">
            Informe um e-mail por linha
          </div>

          <v-textarea
            v-model="form.emails"
            label="Lista de e-mails"
            placeholder="Informe os e-mails"
            :rules="_rules"
            outlined
            auto-grow
          />
        </v-form>
      </section>

      <div class="d-flex justify-end">
        <v-btn
          v-if="!loading"
          class="text-none rounded-lg mr-2"
          :loading="loading"
          text
          @click="close()"
        >
          Cancelar
        </v-btn>

        <v-btn
          class="text-none rounded-lg"
          color="primary"
          :loading="loading"
          depressed
          @click="submit()"
        >
          Confirmar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";

export default {
  name: "ManageMaluStudant",

  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      menuValidate: false,
      form: {
        flag: "malu",
        emails: "",
      },
    };
  },

  computed: {
    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate() || !this.form.flag) return;

      try {
        this.loading = true;

        const payload = {
          ...(this.form.flag === "malu" && { method: "uploadAlunasMalu" }),
          ...(this.form.flag === "carol" && { method: "uploadAlunasCarol" }),
          emails: this.form.emails.replaceAll("\n", ","),
        };

        await request(payload);

        this.handleAlert("Lista adicionada com sucesso", "success");
        this.close();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open() {
      this.dialog = true;

      if (this.$refs.form) this.$refs.form.reset();
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
