<template>
  <v-container fluid>
    <div class="d-flex flex-column flex-sm-row mb-6">
      <span
        class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-4 mb-md-0"
      >
        Solicitações de cancelamento de aplicações
      </span>
    </div>

    <TabNavigation
      :tabs="tabs"
      type="query"
      keyValue="type"
      @change="handleTabChange($event)"
    />

    <v-divider class="surface mb-4" />

    <v-card class="pa-4 rounded-lg" flat>
      <FilterInput :filters="_filters" @filter="handleFilter($event)" />

      <loader v-if="loading.content" />

      <!-- content -->
      <v-data-table
        v-else
        :headers="_headers"
        :items="cancellations"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.brand`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar class="mr-2" size="32" color="background">
              <v-img v-if="item.brand.image" :src="item.brand.image" />

              <v-icon v-else color="surface" small>
                mdi-account-outline
              </v-icon>
            </v-avatar>

            <div>
              {{ item.brand.name }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.creator`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar class="mr-2" size="32" color="background">
              <v-img v-if="item.creator.image" :src="item.creator.image" />

              <v-icon v-else color="surface" small>
                mdi-account-outline
              </v-icon>
            </v-avatar>

            <div>
              {{ item.creator.name }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.requested`]="{ item }">
          {{ item.requested === "1" ? "Marca" : "Criador" }}
        </template>

        <template v-slot:[`item.campaign`]="{ item }">
          {{ item.campaign.name }}
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="mr-1"
            color="primary"
            small
            icon
            @click="handleCancellation(item, true)"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>

          <v-btn
            color="primary"
            small
            icon
            @click="handleCancellation(item, false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <Pagination
        :total="pagination.total"
        :limit="pagination.limit"
        @change="handlePagination($event)"
      />
    </v-card>

    <ManageCancellation ref="manageCancellation" @update="getCancellations()" />
  </v-container>
</template>

<script>
import { request } from "@/services";
import { currencyMask, formatDate, fetchTotalPages } from "@/utils";
import TabNavigation from "@/components/tabNavigation/TabNavigation.vue";
import FilterInput from "@/components/filterInput/FilterInput.vue";
import ManageCancellation from "./partials/manageCancellation/ManageCancellation";
import Pagination from "@/components/pagination/Pagination.vue";

export default {
  components: {
    TabNavigation,
    FilterInput,
    ManageCancellation,
    Pagination,
  },

  data() {
    return {
      loading: {
        content: true,
        hover: false,
      },
      tabs: [
        {
          label: "Em análise",
          value: "in-analysis",
        },
        {
          label: "Rejeitadas",
          value: "rejected",
        },
      ],
      filters: {},
      pagination: {
        page: 1,
        limit: 15,
        total: 0,
      },
      cancellations: [],
    };
  },

  beforeMount() {
    this.getCancellations();
  },

  computed: {
    _headers() {
      const type = this.$route.query.type;

      return [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Criador",
          value: "brand",
        },
        {
          text: "Marca",
          value: "creator",
        },
        {
          text: "Solicitante",
          value: "requested",
        },
        {
          text: "Campanha",
          value: "campaign",
        },
        {
          text: "Data de solicitação",
          value: "createdAt",
        },
        ...(!type || type === "in-analysis"
          ? [{ text: "Ações", value: "actions", width: "6rem" }]
          : []),
      ];
    },

    _filters() {
      return [
        {
          label: "Nome do creator",
          key: "creatorName",
          type: "text",
        },
        {
          label: "Nome da marca",
          key: "brandName",
          type: "text",
        },
      ];
    },
  },

  methods: {
    async getCancellations() {
      try {
        const type = this.$route.query.type;

        const payload = {
          ...((!type || type === "in-analysis") && {
            method: "listCancelamentosAplicacoes",
          }),
          ...(type === "rejected" && {
            method: "listCancelamentosAplicacoesCanceladas",
          }),
          page: this.pagination.page,
          ...(this.filters.creatorName && {
            nameUserFilter: this.filters.creatorName,
          }),
          ...(this.filters.brandName && {
            nomeMarcaFilter: this.filters.brandName,
          }),
        };

        const { data } = await request(payload);

        this.cancellations = data.solicitacoes.map((el) => ({
          id: el.id,
          brand: {
            id: el.branID,
            name: el.nomeMarca,
            image: process.env.VUE_APP_API_URL + "/" + el.fotoMarca,
          },
          creator: {
            id: el.userID,
            name: el.creatorName,
            image: process.env.VUE_APP_API_URL + "/" + el.userImage,
          },
          campaign: {
            id: el.aplicacaoID,
            name: el.nomeCampanha,
            value: Number(el.valorCampanha),
          },
          motive: el.motivo,
          status: el.aplicacaoStatus,
          createdAt: el.dataPedido,
          requested: el.quemPediu,
        }));

        this.pagination.total = fetchTotalPages(data.total);
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading.content = false;
      }
    },

    handleTabChange() {
      this.pagination.page = 1;

      this.getCancellations();
    },

    handleFilter(event) {
      this.filters = {
        ...this.filters,
        ...event,
      };

      this.pagination.page = 1;

      this.getCancellations();
    },

    handlePagination(event) {
      this.pagination.page = event.page;

      this.getCancellations();
    },

    handleCancellation(data, value) {
      this.$refs.manageCancellation.open(data, value);
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    currencyMask,

    formatDate,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
