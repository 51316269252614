import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import { http } from "@/services";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "localStorage",
  storage: window.localStorage, // persistent on localstorage
  modules: ["token"], // persistent variable on state
});

export default new Vuex.Store({
  state: {
    token: null,
  },

  getters: {
    authenticated: ({ token }) => !!token,
    token: ({ token }) => token,
    apiURL: () => process.env.VUE_APP_API_URL,
  },

  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
  },

  actions: {
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
      if (http) http.defaults.headers.common["AdminAuthorization"] = payload;
    },

    setSignOut({ dispatch }) {
      dispatch("setToken", null);
    },
  },

  plugins: [vuexLocal.plugin],

  modules: {},
});
