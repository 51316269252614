<template>
  <div class="pagination-wrapper">
    <div class="d-flex align-center justify-center mt-2">
      <v-btn
        class="mr-2"
        :disabled="page <= 1"
        icon
        small
        @click="handlePagination(-1)"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <span class="text-body-2 font-weight-bold">{{ page }}</span>

      <v-btn
        class="ml-2"
        :disabled="page >= total"
        icon
        small
        @click="handlePagination(1)"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",

  data() {
    return {
      page: 1,
    };
  },

  props: {
    total: {
      type: Number,
      default: 0,
    },

    limit: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    handlePagination(value) {
      this.page += value;

      const payload = {
        page: this.page,
        offset: this.limit * (this.page - 1),
      };

      this.$emit("change", payload);
    },
  },
};
</script>

<style></style>
