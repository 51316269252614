<template>
  <div class="d-flex fill-height darken-purple">
    <v-container fill-height>
      <v-row justify="center">
        <v-col class="d-flex flex-column" cols="12" md="6" lg="5" xl="4">
          <v-card class="px-8 py-12 rounded-lg" light>
            <v-row class="d-flex justify-center mb-2">
              <v-col cols="6" sm="5">
                <v-img :src="require(`@/assets/logo.svg`)" />
              </v-col>
            </v-row>

            <div
              class="text-h6 text-center black--text font-weight-regular mb-2"
            >
              Administrativo
            </div>

            <v-form v-model="valid" ref="form" class="mb-8" :disabled="loading">
              <div @keypress.enter.prevent="submit()">
                <v-text-field
                  v-model="form.username"
                  class="mb-4"
                  label="Usuário"
                  color="secondary"
                  :rules="_rules"
                  @keydown.space.prevent
                />

                <v-text-field
                  v-model="form.password"
                  class="mb-4"
                  label="Senha"
                  color="secondary"
                  :rules="_rules"
                  :append-icon="
                    showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                  "
                  :type="showPassword ? 'text' : 'password'"
                  @keydown.space.prevent
                  @click:append="showPassword = !showPassword"
                />
              </div>
            </v-form>

            <v-btn
              class="rounded-lg text-none"
              color="primary"
              :loading="loading"
              x-large
              block
              @click="submit()"
            >
              Entrar
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { request } from "@/services";

export default {
  name: "Auth",

  data() {
    return {
      valid: false,
      loading: false,
      showPassword: false,
      form: {
        username: "",
        password: "",
      },
    };
  },

  computed: {
    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    ...mapActions(["setToken", "setUser"]),

    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "admLogin",
          login: this.form.username,
          pass: this.form.password,
        };

        await request(payload).then(({ data }) => {
          this.setToken(data.authToken);
          this.$router.push({ path: "/" });
        });
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
