<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card class="pa-4">
      <div class="d-flex align-center mb-6">
        <div class="text-h6">Editar rede social</div>

        <v-spacer />

        <v-btn color="primary" :disabled="loading" small icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-form v-model="valid" ref="form">
        <div class="mb-6" @keypress.enter.prevent="submit()">
          <div class="d-flex align-center mb-8">
            <v-avatar
              size="64"
              class="mr-2"
              :color="form.image ? 'transparent' : 'surface'"
              rounded
            >
              <v-img v-if="form.image" :src="form.image.objectURL" />
              <v-icon v-else dark>mdi-image</v-icon>
            </v-avatar>

            <FileInput
              v-model="form.image"
              ref="fileInput"
              :accept="'image/jpeg,image/jpg,image/png'"
            >
              <v-btn
                class="text-none"
                color="primary"
                text
                @click="selectImg()"
              >
                Selecionar imagem
              </v-btn>
            </FileInput>
          </div>

          <v-text-field
            v-model="form.name"
            label="Nome da rede social"
            class="mb-4"
            :rules="_rules"
          />

          <v-text-field
            v-model="form.link"
            label="Endereço base"
            :rules="_rules"
          />
        </div>

        <v-btn
          class="text-none rounded-lg"
          color="primary"
          :loading="loading"
          depressed
          large
          block
          @click="submit()"
        >
          Salvar alterações
        </v-btn>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { request } from "@/services";
import FileInput from "@/components/fileInput/FileInput.vue";

export default {
  components: {
    FileInput,
  },

  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      socialMediaID: "",
      form: {
        image: null,
        name: "",
        link: "",
      },
    };
  },

  computed: {
    ...mapState(["user"]),

    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "uploadRedeSociais",
          idRedeSocial: this.socialMediaID,
          nomeRede: this.form.name,
          linkRede: this.form.link,
          ...(this.form.image &&
            this.form.image.file && { imgRede: this.form.image.file }),
        };

        await request(payload);

        this.handleAlert("Rede social atualizada com sucesso ", "success");
        this.$emit("update");

        this.close();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open(data) {
      this.socialMediaID = data.id;

      this.form.name = data.name;
      this.form.link = data.link;
      this.form.image = {
        file: null,
        objectURL: data.image,
      };

      this.dialog = true;
    },

    selectImg() {
      this.$refs.fileInput.selectFiles();
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
