<template>
  <v-app v-if="loaded" id="app">
    <v-navigation-drawer
      v-if="_authenticated"
      v-model="drawer"
      :color="$vuetify.breakpoint.lgAndUp ? 'transparent' : 'neutral'"
      floating
      app
    >
      <div class="d-flex flex-column fill-height">
        <v-card class="rounded-lg elevation-0" color="neutral" height="100%">
          <div class="d-flex fill-height">
            <div class="d-flex flex-column fill-width">
              <!-- logo -->
              <div class="d-flex justify-center my-6">
                <v-img
                  :src="require('@/assets/logo.svg')"
                  contain
                  max-width="8rem"
                />
              </div>

              <!-- routes list -->
              <v-list
                class="py-0"
                color="transparent"
                width="100%"
                height="100%"
                dense
                nav
              >
                <router-link
                  v-for="(item, i) in routes"
                  v-slot="{ href, navigate, isActive }"
                  :to="item.path"
                  :key="i"
                  custom
                >
                  <v-list-item
                    class="rounded-lg"
                    :href="href"
                    :class="`${!isActive || 'background'}`"
                    @click="navigate"
                  >
                    <v-list-item-icon class="mr-3">
                      <v-icon color="primary">
                        {{ item.icon }}
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="d-flex">
                      <span
                        :class="item.disabled ? 'text2--text' : 'text1--text'"
                      >
                        {{ item.label }}
                      </span>

                      <v-spacer />
                    </v-list-item-title>
                  </v-list-item>
                </router-link>
              </v-list>

              <!-- append -->
              <v-list class="pt-0" color="transparent" width="100%" dense nav>
                <v-list-item class="rounded-lg" @click="logoff()">
                  <v-list-item-icon class="mr-3">
                    <v-icon color="primary"> mdi-exit-to-app </v-icon>
                  </v-list-item-icon>

                  <v-list-item-title> Sair </v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </div>
        </v-card>
      </div>
    </v-navigation-drawer>

    <v-main class="background" :class="!_authenticated || 'pb-16 pb-md-0'">
      <v-app-bar v-if="_authenticated" class="elevation-0" color="transparent">
        <v-btn
          v-if="$vuetify.breakpoint.mdAndDown"
          class="mr-2"
          icon
          @click="drawer = !drawer"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <v-img
          v-if="$vuetify.breakpoint.smAndDown"
          :src="require('@/assets/logo.svg')"
          contain
          max-width="4rem"
        />

        <v-spacer />
      </v-app-bar>

      <router-view />
    </v-main>

    <AlertBar ref="alertBar" />
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { request } from "./services";
import AlertBar from "./components/alertBar/AlertBar.vue";

export default {
  name: "App",

  data() {
    return {
      loaded: false,
      drawer: true,
      routes: [
        {
          icon: "mdi-view-dashboard",
          label: "Dashboard",
          path: "/dashboard",
        },
        {
          icon: "mdi-account",
          label: "Criadores",
          path: "/creators",
        },
        {
          icon: "mdi-account-multiple",
          label: "Marcas",
          path: "/brands",
        },
        {
          icon: "mdi-swap-vertical-bold",
          label: "Solicitações de saque",
          path: "/withdrawals",
        },
        {
          icon: "mdi-book-cancel-outline",
          label: "Cancelamento de assinatura",
          path: "/unsubscriptions",
        },
        {
          icon: "mdi-file-cancel-outline",
          label: "Cancelamento de aplicações",
          path: "/cancellation-applications",
        },
        {
          icon: "mdi-file-tree-outline",
          label: "Gerenciar nichos",
          path: "/niches",
        },

        {
          icon: "mdi-web",
          label: "Gerenciar redes Sociais",
          path: "/social-media",
        },
        {
          icon: "mdi-video-check",
          label: "Gerenciar tipos de vídeo",
          path: "/video-types",
        },
      ],
    };
  },

  components: {
    AlertBar,
  },

  created() {
    this.handleSession();
  },

  beforeMount() {
    this.$root.$on("alert", this.handleAlert);
  },

  beforeDestroy() {
    this.$root.$off("alert", this.handleAlert);
  },

  computed: {
    ...mapState(["user"]),

    _authenticated() {
      return this.$store.getters.authenticated;
    },
  },

  methods: {
    ...mapActions(["setToken", "setUser", "setSignOut"]),

    // checks if the token is still valid for a session
    async handleSession() {
      const token = this.$store.getters.token;

      if (token) {
        try {
          const payload = {
            method: "checkAdminToken",
          };

          await request(payload);
        } catch {
          this.logoff();
        }
      }

      this.loaded = true;
    },

    handleAlert(event) {
      this.$refs.alertBar.handleAlert(event);
    },

    async logoff() {
      await this.setSignOut();
      this.$router.push({ path: "/auth" }).catch(() => {
        /* ignore */
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
