<template>
  <v-container fluid>
    <div
      class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-6"
    >
      Marcas
    </div>

    <TabNavigation :tabs="tabs" type="path" keyValue="/brands" />

    <v-divider class="surface mb-4" />

    <router-view />
  </v-container>
</template>

<script>
import TabNavigation from "@/components/tabNavigation/TabNavigation.vue";

export default {
  components: {
    TabNavigation,
  },

  data() {
    return {
      tabs: [
        {
          label: "Todos",
          value: "/all",
        },
        {
          label: "Aguardando aprovação",
          value: "/pending-approval",
        },
      ],
    };
  },
};
</script>

<style></style>
