<template>
  <v-container fluid>
    <div class="text-h6 text-sm-h5 font-weight-bold text-left mb-md-6">
      Detalhes do creator
    </div>

    <loader v-if="loading.content" class="mt-6" />

    <section v-else>
      <div class="d-flex align-center justify-end mb-4">
        <v-btn
          class="text-none rounded-lg"
          color="primary"
          depressed
          @click="viewCreator()"
        >
          Acessar conta
        </v-btn>
      </div>

      <v-row class="mb-2">
        <v-col cols="12" md="9" xl="10">
          <v-card class="pa-4 rounded-lg" height="100%" flat>
            <div class="d-flex fill-height flex-column flex-md-row">
              <div class="d-flex flex-column flex-md-row">
                <div class="d-flex flex-column align-center mr-6 mb-4 mb-md-0">
                  <v-avatar size="6rem" color="surface">
                    <v-img v-if="user.image" :src="user.image" />

                    <v-icon v-else size="2.25rem" color="surface lighten-1">
                      mdi-account-outline
                    </v-icon>
                  </v-avatar>

                  <v-chip color="secondary" class="mt-n4" label small>
                    {{ user.subscription.valid ? "Pro" : "Free" }}
                  </v-chip>
                </div>

                <div class="d-flex flex-column">
                  <div class="text-body-1 font-weight-bold">
                    {{ user.name }}

                    <span class="text-body-2"> @{{ user.username }} </span>
                  </div>

                  <div v-if="user.biography" class="text-caption text2--text">
                    {{ user.biography }}
                  </div>

                  <div class="text-body-2 mt-2">
                    {{ user.email }}
                  </div>

                  <div v-if="user.phone" class="text-body-2">
                    {{ user.phone }}
                  </div>

                  <div class="d-flex align-start">
                    <v-form
                      v-model="valid.document"
                      ref="formDocument"
                      autocomplete="off"
                    >
                      <v-text-field
                        v-model="form.document"
                        v-mask="['###.###.###-##', '##.###.###/####-##']"
                        placeholder="CPF/CNPJ"
                        hide-details="auto"
                        :disabled="!editDocument || loading.document"
                        :rules="_documentRules"
                        dense
                      ></v-text-field>
                    </v-form>

                    <div class="ml-2 mt-1">
                      <v-btn
                        v-if="!editDocument"
                        small
                        icon
                        @click="editDocument = true"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>

                      <div v-else>
                        <v-btn
                          :disabled="loading.document"
                          small
                          icon
                          @click="cancelChangeDocument()"
                        >
                          <v-icon color="red">mdi-close</v-icon>
                        </v-btn>

                        <v-btn
                          :disabled="loading.document"
                          :loading="loading.document"
                          small
                          icon
                          @click="changeDocument()"
                        >
                          <v-icon color="green">mdi-check</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <div v-if="user.socialMedia.length" class="d-flex mt-4">
                    <a
                      v-for="(item, i) in user.socialMedia"
                      :href="item.link + item.username"
                      target="_blank"
                      class="mr-2"
                      :key="i"
                    >
                      <v-avatar color="surface" size="1.5rem">
                        <v-img
                          :src="$store.getters.apiURL + '/' + item.image"
                        />
                      </v-avatar>
                    </a>
                  </div>
                </div>
              </div>

              <v-spacer />

              <div class="d-flex flex-column mt-4 mt-md-0">
                <div class="text-body-2 font-weight-bold">
                  Tag de verificação
                </div>

                <v-switch
                  v-model="form.flag"
                  class="mt-1"
                  label="Malu"
                  color="primary"
                  value="malu"
                  :loading="loading.flagMalu"
                  :disabled="loading.flagMalu || loading.flagCarol"
                  hide-details
                  dense
                  inset
                  @click="handleChangeFlag()"
                ></v-switch>

                <v-switch
                  v-model="form.flag"
                  class="mt-1"
                  label="Carol"
                  color="primary"
                  value="carol"
                  :loading="loading.flagCarol"
                  :disabled="loading.flagMalu || loading.flagCarol"
                  hide-details
                  dense
                  inset
                  @click="handleChangeFlag()"
                ></v-switch>
              </div>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" xl="2">
          <v-card class="pa-4 rounded-lg" height="100%" flat>
            <!--  <v-switch
              v-model="form.demoAccount"
              class="ma-0 pa-0 mb-2"
              label="Conta demo"
              color="primary"
              :loading="loading.demo"
              :disabled="loading.demo"
              hide-details
              dense
              inset
              @click="handleChangeDemoAccount()"
            ></v-switch> -->

            <div class="primary--text text-h6 mb-n1">
              R$ {{ currencyMask(user.balance) }}
            </div>

            <div class="mb-2 text-body-2">Saldo da conta</div>

            <div class="primary--text text-h6 mb-n1">
              R$ {{ currencyMask(user.withdrawBalance) }}
            </div>

            <div class="text-body-2 mb-4">Saldo disponível para saque</div>

            <div v-if="user.plan === '1'" class="text-caption text2--text">
              Próxima cobrança: {{ user.paymentDate }}
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-card class="pa-4 rounded-lg mb-6" flat>
        <div class="font-weight-bold mb-3">Documentação</div>

        <v-list-item v-if="user.documents" class="foreground rounded-lg">
          <div
            class="d-flex flex-column flex-md-row align-md-center fill-width"
          >
            <v-list-item-content
              :class="{ 'fill-width': $vuetify.breakpoint.smAndDown }"
            >
              <v-list-item-title>
                <a target="_blank" :href="user.documents.document">
                  Visualizar
                </a>
              </v-list-item-title>

              <v-list-item-subtitle> Documento </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content
              :class="{ 'fill-width': $vuetify.breakpoint.smAndDown }"
            >
              <v-list-item-title>
                <a target="_blank" :href="user.documents.selfie">Visualizar</a>
              </v-list-item-title>

              <v-list-item-subtitle>
                Selfie com documento
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content
              :class="{ 'fill-width': $vuetify.breakpoint.smAndDown }"
            >
              <v-list-item-title>
                {{ handleStatus(user.documents.status) }}
              </v-list-item-title>

              <v-list-item-subtitle> Status </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action-text
              :class="{ 'fill-width': $vuetify.breakpoint.smAndDown }"
            >
              <v-menu
                v-if="
                  String(user.documents.status) === '0' ||
                  String(user.documents.status) === '1'
                "
                :close-on-content-click="false"
                content-class="mt-2"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-model="rejectDocument.show"
                    v-bind="attrs"
                    v-on="on"
                    class="text-none mr-2"
                    color="primary"
                    text
                    small
                  >
                    Recusar
                  </v-btn>
                </template>

                <v-card class="py-2 px-4" width="300">
                  <v-text-field
                    v-model="rejectDocument.motive"
                    label="Motivo da rejeição"
                  />

                  <div class="d-flex">
                    <v-spacer />

                    <v-btn
                      class="text-none rounded-lg"
                      color="primary"
                      depressed
                      small
                      @click="
                        rejectDocument.show = false;
                        changeDocumentStatus(-1, rejectDocument.motive);
                      "
                    >
                      Salvar
                    </v-btn>
                  </div>
                </v-card>
              </v-menu>

              <v-btn
                v-if="
                  String(user.documents.status) === '0' ||
                  String(user.documents.status) === '-1'
                "
                class="text-none mr-2"
                color="primary"
                depressed
                small
                @click="changeDocumentStatus(1)"
              >
                Aprovar
              </v-btn>
            </v-list-item-action-text>
          </div>
        </v-list-item>

        <div v-else class="d-flex fill-width justify-center grey--text">
          Envio pendente
        </div>
      </v-card>

      <v-card v-if="user.bankAccount" class="pa-4 rounded-lg mb-6" flat>
        <div class="font-weight-bold mb-3">Dados bancários</div>

        <v-list-item class="foreground rounded-l mb-2">
          <div
            class="d-flex flex-column flex-md-row align-md-center fill-width"
          >
            <v-list-item-content
              :class="{ 'fill-width': $vuetify.breakpoint.smAndDown }"
            >
              <v-list-item-title>
                {{ user.bankAccount.bank }}
              </v-list-item-title>

              <v-list-item-subtitle> Banco </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content
              :class="{ 'fill-width': $vuetify.breakpoint.smAndDown }"
            >
              <v-list-item-title>
                {{ user.bankAccount.agency }}
              </v-list-item-title>

              <v-list-item-subtitle> Agencia </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content
              :class="{ 'fill-width': $vuetify.breakpoint.smAndDown }"
            >
              <v-list-item-title>
                {{ user.bankAccount.account }} - {{ user.bankAccount.digit }}
              </v-list-item-title>

              <v-list-item-subtitle> Conta </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content
              :class="{ 'fill-width': $vuetify.breakpoint.smAndDown }"
            >
              <v-list-item-title>
                {{ user.bankAccount.date }}
              </v-list-item-title>

              <v-list-item-subtitle> Data de envio </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content
              :class="{ 'fill-width': $vuetify.breakpoint.smAndDown }"
            >
              <v-list-item-title>
                {{ handleStatus(user.bankAccount.status) }}
              </v-list-item-title>

              <v-list-item-subtitle> Status </v-list-item-subtitle>
            </v-list-item-content>
          </div>
        </v-list-item>

        <div class="d-flex justify-end">
          <v-menu
            v-if="
              String(user.bankAccount.status) === '0' ||
              String(user.bankAccount.status) === '1'
            "
            :close-on-content-click="false"
            content-class="mt-2"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-model="rejectBank.show"
                v-bind="attrs"
                v-on="on"
                class="text-none mr-2"
                color="primary"
                text
                small
              >
                Recusar
              </v-btn>
            </template>

            <v-card class="py-2 px-4" width="300">
              <v-text-field
                v-model="rejectBank.motive"
                label="Motivo da rejeição"
              />

              <div class="d-flex">
                <v-spacer />

                <v-btn
                  class="text-none rounded-lg"
                  color="primary"
                  depressed
                  small
                  @click="
                    rejectBank.show = false;
                    changeBankStatus(-1, rejectBank.motive);
                  "
                >
                  Salvar
                </v-btn>
              </div>
            </v-card>
          </v-menu>

          <v-btn
            v-if="
              String(user.bankAccount.status) === '0' ||
              String(user.bankAccount.status) === '-1'
            "
            class="text-none mr-2"
            color="primary"
            depressed
            small
            @click="changeBankStatus(1)"
          >
            Aprovar
          </v-btn>
        </div>
      </v-card>

      <v-card class="pa-4 rounded-lg mb-6" flat>
        <div class="d-flex mb-3">
          <div class="font-weight-bold">Assinaturas</div>

          <v-spacer />

          <v-btn
            class="text-none rounded-lg"
            color="primary"
            depressed
            small
            @click="handleAddSignature()"
          >
            Adicionar assinatura
          </v-btn>
        </div>

        <v-data-table
          :headers="headers"
          :items="signatures"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ formatDate(item.updatedAt) }}
          </template>

          <template v-slot:[`item.nextCharge`]="{ item }">
            {{ formatDate(item.nextCharge) }}
          </template>

          <template v-slot:[`item.value`]="{ item }">
            {{ currencyMask(item.value) }}
          </template>

          <template v-slot:[`item.active`]="{ item }">
            {{ item.active ? "Sim" : "Não" }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              color="primary"
              small
              icon
              @click="handleCancelSignature(item)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </section>

    <AddSignature
      ref="addSignature"
      @update="
        getUserData();
        getSignatures();
      "
    />

    <CancelSignature
      ref="cancelSignature"
      @update="
        getUserData();
        getSignatures();
      "
    />
  </v-container>
</template>

<script>
import { request } from "@/services";
import { currencyMask, formatDate } from "@/utils";
import { mask } from "vue-the-mask";
import AddSignature from "./partials/addSignature/AddSignature";
import CancelSignature from "./partials/cancelSignature/CancelSignature";

const moment = require("moment");
moment.locale("pt-BR");

export default {
  components: {
    AddSignature,
    CancelSignature,
  },

  directives: {
    mask,
  },

  data() {
    return {
      loading: {
        content: true,
        document: false,
        flagMalu: false,
        flagCarol: false,
        demo: false,
      },
      user: null,
      editDocument: false,
      valid: {
        document: false,
      },
      rejectDocument: {
        show: false,
        motive: "",
      },
      rejectBank: {
        show: false,
        motive: "",
      },
      form: {
        demoAccount: false,
        flag: "",
        document: "",
      },
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Descrição",
          value: "description",
        },
        {
          text: "Data de solicitação",
          value: "createdAt",
        },
        {
          text: "Data de atualização",
          value: "updatedAt",
        },
        {
          text: "Próxima cobrança",
          value: "nextCharge",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Ativa",
          value: "active",
        },
        {
          text: "Valor",
          value: "value",
        },
        {
          text: "Ações",
          value: "actions",
        },
      ],
      signatures: [],
    };
  },

  beforeMount() {
    this.getUserData();
    this.getSignatures();
  },

  computed: {
    _documentRules() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => v.length === 14 || v.length === 18 || "Numeração inválida",
      ];
    },
  },

  methods: {
    async getUserData() {
      try {
        const payload = {
          method: "getUserData",
          userID: this.$route.params.id,
        };

        const { data } = await request(payload);

        this.user = {
          id: data.userData.id,
          username: data.userData.username,
          email: data.userData.email,
          phone: data.userData.telefone,
          name: data.userData.nome,
          biography: data.userData.biografia,
          document: data.userData.cpfCnpj,
          plan: data.userData.plano,
          maluFlag: data.userData.alunoMalu === "1",
          carolFlag: data.userData.alunaCarol === "1",
          registerDate: moment(data.userData.registerDate).format(
            "DD/MM/YYYY hh:mm"
          ),
          paymentDate: moment(data.userData.proxCobranca).format(
            "DD/MM/YYYY hh:mm"
          ),
          image: data.userData.userImage
            ? this.$store.getters.apiURL + "/" + data.userData.userImage
            : "",
          ...(data.userData.bankAccount && {
            bankAccount: {
              id: data.userData.bankAccount.id,
              bank: data.userData.bankAccount.banco,
              agency: data.userData.bankAccount.agencia,
              account: data.userData.bankAccount.conta,
              digit: data.userData.bankAccount.digito,
              date: moment(data.userData.bankAccount.dataEnvio).format(
                "DD/MM/YYYY hh:mm"
              ),
              status: data.userData.bankAccount.status,
              pixKey: {
                type: data.userData.bankAccount.tipoChave || "",
                key: data.userData.bankAccount.chavePix || "",
              },
            },
          }),
          documents: data.userData.sendDocs
            ? {
                document:
                  this.$store.getters.apiURL +
                  "/" +
                  data.userData.documents.docImage,
                selfie:
                  this.$store.getters.apiURL +
                  "/" +
                  data.userData.documents.selfie,
                status: data.userData.documents.status,
                id: data.userData.documents.id,
                motive: data.userData.documents.motivoRecuso,
              }
            : null,
          socialMedia: data.userData.redesSocias.map((el) => ({
            id: el.redeID,
            image: el.imgRede,
            link: el.linkRede,
            name: el.nomeRede,
            username: el.userRedeSocial,
          })),
          balance: data.userData.saldo,
          withdrawBalance: data.userData.saldoParaSacar,
          subscription: {
            freeTrial: data.userData.assinatura.free,
            valid: data.userData.assinatura.valida,
          },
          demoAccount: data.userData.contaDemo === "1",
        };

        this.form.document = this.user.document;
        this.form.demoAccount = this.user.demoAccount;

        if (this.user.maluFlag) {
          this.form.flag = "malu";
        }

        if (this.user.carolFlag) {
          this.form.flag = "carol";
        }
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading.content = false;
      }
    },

    async getSignatures() {
      try {
        const payload = {
          method: "listCreatorsSubscriptions",
          userID: this.$route.params.id,
        };

        const { data } = await request(payload);

        this.signatures = data.subscriptions.map((el) => ({
          id: el.id,
          subscriptionID: el.assinaturaID,
          description: el.descricao,
          createdAt: el.dataCriacao,
          updatedAt: el.ultimaAlteraceo,
          nextCharge: el.proxCobranca,
          status: el.statusAssinatura,
          valid: el.valid === "1",
          value: Number(el.valor),
        }));
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async handleChangeDemoAccount() {
      try {
        this.loading.demo = true;

        const payload = {
          method: "setContaDemo",
          status: this.form.demoAccount ? "1" : "0",
          userID: this.$route.params.id,
        };

        await request(payload);

        this.handleAlert("Conta demo alterada com sucesso", "success");
      } catch (error) {
        this.form.demoAccount = !this.form.demoAccount;
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading.demo = false;
      }
    },

    async handleChangeFlag() {
      if (!this.form.flag || this.form.flag === "malu")
        this.loading.flagMalu = true;
      if (!this.form.flag || this.form.flag === "carol")
        this.loading.flagCarol = true;

      await Promise.all([this.setMaluFlag(), this.setCarolFlag()]);

      if (!this.form.flag || this.form.flag === "malu")
        this.loading.flagMalu = false;
      if (!this.form.flag || this.form.flag === "carol")
        this.loading.flagCarol = false;
    },

    async setMaluFlag() {
      try {
        const payload = {
          method: "setAlunoMalu",
          status: this.form.flag === "malu" ? "1" : "0",
          userID: this.$route.params.id,
        };

        await request(payload);

        this.handleAlert("Tag alterada com sucesso", "success");
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async setCarolFlag() {
      try {
        const payload = {
          method: "setAlunoCarol",
          status: this.form.flag === "carol" ? "1" : "0",
          userID: this.$route.params.id,
        };

        await request(payload);

        this.handleAlert("Tag alterada com sucesso", "success");
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async viewCreator() {
      try {
        const payload = {
          method: "getUserToken",
          userID: this.$route.params.id,
        };

        const { data } = await request(payload);

        const url = `${process.env.VUE_APP_CLIENT_CREATOR_URL}/auth?tk=${data.token}`;
        window.open(url, "_target");
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async changeDocumentStatus(status, motive = "") {
      try {
        const payload = {
          method: "changeDocumentoStatus",
          userID: this.$route.params.id,
          status: status,
          motivoRecusa: motive,
        };

        await request(payload);

        this.handleAlert("Alterações salvas", "success");
        this.getUserData();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async changeBankStatus(status, motive = "") {
      try {
        const payload = {
          method: "changeBankAccountStatus",
          userID: this.$route.params.id,
          status: status,
          motivoRecusa: motive,
        };

        await request(payload);

        this.handleAlert("Alterações salvas", "success");
        this.getUserData();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async changeDocument() {
      if (!this.$refs.formDocument.validate()) return;

      try {
        this.loading.document = true;

        const payload = {
          method: "editChaveCPFCNPJ",
          userID: this.$route.params.id,
          cpfcnpj: this.form.document,
        };

        await request(payload);

        this.handleAlert("Alterações salvas", "success");
        this.getUserData();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.editDocument = false;
        this.loading.document = false;
      }
    },

    cancelChangeDocument() {
      this.form.document = this.user.document;
      this.editDocument = false;
    },

    handleStatus(value) {
      switch (String(value)) {
        case "-1":
          return "Rejeitado";
        case "0":
          return "Em análise";
        case "1":
          return "Aprovado";
      }
    },

    handlePixType(value) {
      const options = {
        0: "CPF/CNPJ",
        1: "E-mail",
        2: "Telefone",
        3: "Chave Aleatória",
      };

      return options[Number(value)];
    },

    handleAddSignature() {
      this.$refs.addSignature.open();
    },

    handleCancelSignature(data) {
      this.$refs.cancelSignature.open(data);
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    currencyMask,

    formatDate,
  },
};
</script>

<style></style>
