<template>
  <div class="file-input-wrapper">
    <v-card class="rounded-lg pa-1 mb-3" color="background" flat>
      <div class="d-flex">
        <div class="d-flex align-center flex-wrap">
          <v-menu
            v-for="(item, i) in filters"
            v-model="menuStates[i]"
            :key="i"
            :close-on-content-click="false"
            content-class="mt-2 rounded-lg"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" class="ma-1" outlined>
                <div class="text2--text">
                  <span>{{ item.label }}</span>

                  <span v-if="filtered[item.key]">
                    :
                    {{
                      item.type === "select"
                        ? item.items.find(
                            (el) => el.value === filtered[item.key]
                          ).text
                        : filtered[item.key]
                    }}
                    <v-icon
                      class="ml-1"
                      small
                      @click.stop.prevent="removeFilter(item.key, i)"
                    >
                      mdi-close
                    </v-icon>
                  </span>

                  <v-icon v-else class="ml-1" small>
                    mdi-plus-circle-outline
                  </v-icon>
                </div>
              </v-chip>
            </template>

            <v-card class="py-2 px-4 rounded-lg" width="300">
              <v-text-field
                v-if="item.type === 'text' && !item.mask"
                v-model="filtered[item.key]"
                :label="item.label"
              />

              <v-text-field
                v-if="item.type === 'text' && item.mask"
                v-model="filtered[item.key]"
                v-mask="item.mask"
                :placeholder="item.mask"
                :label="item.label"
              />

              <v-text-field
                v-else-if="item.type === 'date'"
                v-model="filtered[item.key]"
                v-mask="'##/##/####'"
                placeholder="DD/MM/YYYY"
                :label="item.label"
              />

              <v-select
                v-else-if="item.type === 'select'"
                v-model="filtered[item.key]"
                item-text="text"
                item-value="value"
                :items="item.items"
                :label="item.label"
              />

              <div class="d-flex">
                <v-spacer />

                <v-btn
                  class="text-none rounded-lg"
                  color="primary"
                  depressed
                  small
                  @click="submitFilters(i)"
                >
                  Buscar
                </v-btn>
              </div>
            </v-card>
          </v-menu>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  directives: {
    mask,
  },

  data() {
    return {
      menuStates: [],
      filtered: {},
    };
  },

  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    submitFilters(index) {
      this.$emit("filter", this.filtered);
      this.menuStates[index] = false;
      this.$forceUpdate();
    },

    removeFilter(filterKey, index) {
      this.filtered[filterKey] = "";
      this.submitFilters(index);
    },
  },
};
</script>

<style></style>
