<template>
  <div id="chart">
    <apexchart
      type="bar"
      height="350"
      :options="_chartOptions"
      :series="_series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },

  props: {
    series: {
      type: Array,
      default: () => [],
    },

    categories: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    _chartOptions() {
      return {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            barHeight: "85%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
            },
            borderRadius: 10,
          },
        },
        colors: ["#533EF9"],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#fff"],
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: this.categories,
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        fill: {
          colors: ["#533EF9"],
        },
        grid: {
          show: false, // Desativa as linhas de grade
        },
        axisBorder: {
          show: false, // Desativa as linhas do eixo x
        },
      };
    },

    _series() {
      return [
        {
          name: "Total",
          data: this.series,
        },
      ];
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
