<template>
  <v-container fluid>
    <div class="d-flex flex-column flex-sm-row mb-6">
      <span
        class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-4 mb-md-0"
      >
        Criadores
      </span>

      <v-spacer />

      <v-btn
        class="text-none"
        color="primary"
        depressed
        small
        @click="handleStudant()"
      >
        <v-icon left>mdi-plus</v-icon>
        Tag de verificação
      </v-btn>
    </div>

    <TabNavigation :tabs="tabs" type="path" keyValue="/creators" />

    <v-divider class="surface mb-4" />

    <router-view />

    <ManageMaluStudant ref="manageMaluStudant" />
  </v-container>
</template>

<script>
import TabNavigation from "@/components/tabNavigation/TabNavigation.vue";
import ManageMaluStudant from "./partials/manageMaluStudant/ManageMaluStudant";

export default {
  components: {
    TabNavigation,
    ManageMaluStudant,
  },

  data() {
    return {
      tabs: [
        {
          label: "Todos",
          value: "/all",
        },
        {
          label: "Aguardando aprovação",
          value: "/pending-approval",
        },
      ],
    };
  },

  methods: {
    handleStudant() {
      this.$refs.manageMaluStudant.open();
    },
  },
};
</script>

<style></style>
