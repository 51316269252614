<template>
  <div class="bank-data-wrapper">
    <v-card v-if="brand.bankAccount" class="pa-4 rounded-lg mb-6" flat>
      <div class="font-weight-bold mb-3">Dados bancários</div>

      <v-card class="foreground rounded-lg mb-2" flat>
        <v-row no-gutters>
          <v-col class="pa-4" cols="12" sm="6" md="3">
            <div class="text-body-1">{{ brand.bankAccount.bank }}</div>
            <div class="text-body-2">Banco</div>
          </v-col>

          <v-col class="pa-4" cols="12" sm="6" md="3">
            <div class="text-body-1">{{ brand.bankAccount.agency }}</div>
            <div class="text-body-2">Agência</div>
          </v-col>

          <v-col class="pa-4" cols="12" sm="6" md="3">
            <div class="text-body-1">
              {{ brand.bankAccount.account }} - {{ brand.bankAccount.digit }}
            </div>
            <div class="text-body-2">Conta</div>
          </v-col>

          <v-col class="pa-4" cols="12" sm="6" md="3">
            <div class="text-body-1">
              {{ formatDate(brand.bankAccount.date) }}
            </div>
            <div class="text-body-2">Data de envio</div>
          </v-col>
        </v-row>
      </v-card>

      <v-card
        v-if="brand.bankAccount.pixKey.type && brand.bankAccount.pixKey.key"
        class="foreground rounded-lg mb-2"
        flat
      >
        <v-row no-gutters>
          <v-col class="pa-4" cols="12" sm="6" md="3">
            <div class="text-body-1">
              {{ handlePixType(brand.bankAccount.pixKey.type) }}
            </div>
            <div class="text-body-2">Tipo de chave</div>
          </v-col>

          <v-col class="pa-4" cols="12" sm="6" md="3">
            <div class="text-body-1">{{ brand.bankAccount.pixKey.key }}</div>
            <div class="text-body-2">Chave</div>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { formatDate } from "@/utils";

export default {
  name: "BankData",

  props: {
    brand: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    handlePixType(value) {
      const options = {
        0: "CPF/CNPJ",
        1: "E-mail",
        2: "Telefone",
        3: "Chave Aleatória",
      };

      return options[Number(value)];
    },

    formatDate,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
